import React from 'react'

const ThermorossiSVG = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 454.3 80">
            <g>
                <g>
                    <g>
                        <path class="st0" d="M94.9,55.2c-3,3.1-7.2,4.8-11.6,4.8c-4.3,0-8.4-1.4-11.4-4.5c-4.4-4.4-5-9.8-5-16.4c0-6.6,0.6-12,5-16.4
				c3.1-3.1,7.1-4.6,11.4-4.6c4.3,0,8.5,1.6,11.6,4.7c2.3,2.4,3.5,5.6,3.9,8.8h-7.5c-0.2-1.8-0.8-3-1.8-4.3
				c-1.3-1.7-3.6-2.5-6.1-2.5c-2.3,0-4.6,1-6.2,2.7c-2.8,2.9-2.8,7.9-2.8,11.6c0,3.7,0,8.7,2.8,11.6c1.6,1.7,3.9,2.7,6.2,2.7
				c2.5,0,4.8-0.9,6.1-2.6c1-1.3,1.7-2.7,1.8-4.4h7.5C98.4,49.6,97.2,52.9,94.9,55.2z"/>
                        <path class="st0" d="M133.7,55.5c-3.1,3.1-7.3,4.6-11.6,4.6c-4.3,0-8.5-1.5-11.6-4.6c-4.4-4.4-5.1-9.7-5.1-16.3
				c0-6.6,0.7-12,5.1-16.4c3.1-3.1,7.3-4.6,11.6-4.6c4.3,0,8.5,1.5,11.6,4.6c4.4,4.4,5.1,9.8,5.1,16.4
				C138.8,45.7,138.1,51.1,133.7,55.5z M128.5,27.5c-1.6-1.7-4-2.7-6.4-2.7c-2.3,0-4.8,1-6.4,2.7c-2.8,2.9-2.9,7.9-2.9,11.6
				c0,3.7,0.1,8.7,2.9,11.6c1.6,1.7,4,2.7,6.4,2.7c2.3,0,4.8-1,6.4-2.7c2.8-2.9,2.9-7.9,2.9-11.6C131.4,35.4,131.2,30.4,128.5,27.5z
				"/>
                        <path class="st0" d="M173.3,59.3l-17.5-27.7v27.7h-7.2V18.9h7.8l17.3,27.3V18.9h7.2v40.4H173.3z" />
                        <path class="st0" d="M218.9,55.7c-3.1,3.1-7.8,4.3-12.8,4.3c-4.3,0-8.8-1.1-12.1-4.2c-2.4-2.2-4.2-5.6-4.2-9.4h7.5
				c0,2,0.9,3.8,2.1,5c1.7,1.7,4.4,2.2,6.8,2.2c2.8,0,5.5-0.6,7-2.2c0.9-1,1.6-1.9,1.6-3.9c0-3-2.3-4.3-4.8-4.8
				c-2.7-0.5-6.3-0.6-8.9-1.1c-5.6-0.9-10.2-4.6-10.2-11.3c0-3.2,1.4-5.9,3.5-8c2.7-2.6,7.1-4.3,11.8-4.3c4.3,0,8.5,1.4,11.3,3.9
				c2.4,2.3,4,5.2,4.1,8.6h-7.5c-0.1-1.4-0.8-3-1.8-4c-1.3-1.4-4-2.2-6.1-2.2c-2.3,0-4.9,0.5-6.5,2.2c-0.9,0.9-1.4,1.9-1.4,3.4
				c0,2.6,1.7,4,4.1,4.4c2.6,0.4,6.1,0.7,8.8,1c6,0.9,11.1,4.8,11.1,11.7C222.3,51,220.9,53.7,218.9,55.7z"/>
                        <path class="st0" d="M245.3,25.8v33.5h-7.3V25.8h-12v-6.8h31.3v6.8H245.3z" />
                        <path class="st0" d="M287.9,59.3L281.2,44h-8.9v15.3h-7.4V18.9h17c9.6,0,14,6,14,12.5c0,5.4-3,9.7-7.4,11.3l7.4,16.5H287.9z
				 M281.9,25.4h-9.7v12.1h9.8c4.2,0,6.5-2.2,6.5-6C288.6,28.2,286.1,25.4,281.9,25.4z"/>
                        <path class="st0" d="M319.7,60c-10.4,0-15.8-6.6-15.8-16.2V18.9h7.3v24.8c0,5.7,2.6,9.7,8.5,9.7c5.9,0,8.5-3.9,8.5-9.7V18.9h7.3
				v24.9C335.5,53.4,330.1,60,319.7,60z"/>
                        <path class="st0" d="M361.4,25.8v33.5H354V25.8h-12v-6.8h31.3v6.8H361.4z" />
                        <path class="st0" d="M381,59.3V18.9h28.8v6.7h-21.4V35h18.8v6.6h-18.8v11h21.4v6.7H381z" />
                        <path class="st0" d="M444.7,55.2c-3,3.1-7.2,4.8-11.6,4.8c-4.3,0-8.4-1.4-11.4-4.5c-4.4-4.4-5-9.8-5-16.4c0-6.6,0.6-12,5-16.4
				c3.1-3.1,7.1-4.6,11.4-4.6c4.3,0,8.5,1.6,11.6,4.7c2.3,2.4,3.5,5.6,3.9,8.8H441c-0.2-1.8-0.8-3-1.8-4.3c-1.3-1.7-3.6-2.5-6.1-2.5
				c-2.3,0-4.6,1-6.2,2.7c-2.8,2.9-2.8,7.9-2.8,11.6c0,3.7,0,8.7,2.8,11.6c1.6,1.7,3.9,2.7,6.2,2.7c2.5,0,4.8-0.9,6.1-2.6
				c1-1.3,1.7-2.7,1.8-4.4h7.5C448.2,49.6,447,52.9,444.7,55.2z"/>
                    </g>
                </g>
                <g>
                    <rect x="24.9" y="33.8" class="st1" width="9.6" height="9.6" />
                    <polygon class="st2" points="5.8,14.6 5.8,62.7 53.7,62.7 53.7,43.4 44.1,43.4 44.1,53.1 15.4,53.1 15.4,24.2 44.1,24.2 
			44.1,33.9 53.7,33.9 53.7,14.6 		"/>
                </g>
            </g>
        </svg>
    )
}

export default ThermorossiSVG